// If your design has an absolute, transparent header (i.e. it sits on top of the page's banner image)
// then you might run into some issues when, for example, you want your top level nav items to be in
// white; which would look great on an absolute, transparent header with a gradient, but as soon as you
// go into a page with NO banner, you're probably going to have white nav on white background.

// I decided to collect all these into this partial rather than write them into their component partial
// An important part of these overwrites is to not generate any more CSS after compile if we don't need
// to. So if we're not resetting them then do not add to the CSS output, there's no point.

.page-no-banner {
  // Header
  @if $no-banner-header-background-colour != $header-background-colour {
    .pageHeader {
      background-color: $no-banner-header-background-colour;
    }
  }

  // Logo
  @if $no-banner-logo != $logo {
    .mainLogo {
      background-image: url($assets-path+$no-banner-logo);
      @media only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        background-image: url($assets-path+$no-banner-logo-retina);
      }
    }
  }

  // Tagline
  @if ($tagline-enabled == true) and ($no-banner-tagline-colour != $tagline-colour) {
    .tagline {
      color: $no-banner-tagline-colour;
    }
  }

  // Header search
  @if ($header-search-enabled) {
    @media (min-width: $header-search-breakpoint + 1px) {
      .header-search {
        .searchContainer input {
          @if $no-banner-header-search-input-border-colour != $header-search-input-border-colour {
            border-color: $no-banner-header-search-input-border-colour;
          }
          @if $no-banner-header-search-input-background-colour != $header-search-input-background-colour {
            background-color: $no-banner-header-search-input-background-colour;
          }
          @if $no-banner-header-search-input-placeholder-colour != $header-search-input-placeholder-colour {
            &::placeholder {
              color: $no-banner-header-search-input-placeholder-colour;
            }
          }
        }
        button {
          @if $no-banner-header-search-button-background-colour != $header-search-button-background-colour {
            background-color: $no-banner-header-search-button-background-colour;
          }
          @if $no-banner-header-search-button-icon-colour != $header-search-button-icon-colour {
            &:before {
              color: $no-banner-header-search-button-icon-colour;
            }
          }
        }
      }
    }
  }

  // Social icons
  @if $no-banner-social-icons-header-background-colour != $social-icons-header-background-colour {
    @media (min-width: $header-social-icons-breakpoint + 1px) {
      .mainCallToAction .socialIcons a {
        @if $no-banner-social-icons-header-background-colour != "brand" {
          background-color: $no-banner-social-icons-header-background-colour;
        }

        &:hover {
          @if $no-banner-social-icons-header-background-colour != "brand" {
            @include button-hover-state($no-banner-social-icons-header-background-colour);
          }

          @if $no-banner-social-icons-header-hover-colour != "brand" {
            &:before {
              color: $no-banner-social-icons-header-hover-colour;
            }
          }
        }

        &:before {
          @if $no-banner-social-icons-header-colour != "brand" {
            color: $no-banner-social-icons-header-colour;
          }
        }

        &.twitter {
          @include social--icons-brand-colours(
            $brand-colour: $twitter-brand-colour,
            $background-colour: $no-banner-social-icons-header-background-colour,
            $icon-colour: $no-banner-social-icons-header-colour,
            $hover-icon-colour: $no-banner-social-icons-header-hover-colour
          );
        }

        &.facebook {
          @include social--icons-brand-colours(
            $brand-colour: $facebook-brand-colour,
            $background-colour: $no-banner-social-icons-header-background-colour,
            $icon-colour: $no-banner-social-icons-header-colour,
            $hover-icon-colour: $no-banner-social-icons-header-hover-colour
          );
        }

        &.youtube {
          @include social--icons-brand-colours(
            $brand-colour: $youtube-brand-colour,
            $background-colour: $no-banner-social-icons-header-background-colour,
            $icon-colour: $no-banner-social-icons-header-colour,
            $hover-icon-colour: $no-banner-social-icons-header-hover-colour
          );
        }

        &.instagram {
          @include social--icons-brand-colours(
            $brand-colour: $instagram-brand-colour,
            $background-colour: $no-banner-social-icons-header-background-colour,
            $icon-colour: $no-banner-social-icons-header-colour,
            $hover-icon-colour: $no-banner-social-icons-header-hover-colour
          );
        }

        &.linkedin {
          @include social--icons-brand-colours(
            $brand-colour: $linkedin-brand-colour,
            $background-colour: $no-banner-social-icons-header-background-colour,
            $icon-colour: $no-banner-social-icons-header-colour,
            $hover-icon-colour: $no-banner-social-icons-header-hover-colour
          );
        }

        &.pinterest {
          @include social--icons-brand-colours(
            $brand-colour: $pinterest-brand-colour,
            $background-colour: $no-banner-social-icons-header-background-colour,
            $icon-colour: $no-banner-social-icons-header-colour,
            $hover-icon-colour: $no-banner-social-icons-header-hover-colour
          );
        }

        &.tripadvisor {
          @include social--icons-brand-colours(
            $brand-colour: $tripadvisor-brand-colour,
            $background-colour: $no-banner-social-icons-header-background-colour,
            $icon-colour: $no-banner-social-icons-header-colour,
            $hover-icon-colour: $no-banner-social-icons-header-hover-colour
          );
        }
      }
    }
  }

  @if $no-banner-social-icons-header-colour != $social-icons-header-colour {
    @media (min-width: $header-social-icons-breakpoint + 1px) {
      .mainCallToAction .socialIcons a:before {
        color: $no-banner-social-icons-header-colour;
      }
    }
  }

  // Navigation
  @if ($nav-type == normal) {
    @media (min-width: $nav-breakpoint + 1px) {
      // Top level items
      @if $no-banner-nav-top-level-item-colour != $nav-top-level-item-colour {
        .menuMain .topLevel li.level1 {
          > a {
            color: $no-banner-nav-top-level-item-colour;
          }

          &:hover > a {
            color: $no-banner-nav-top-level-item-hover-colour;
          }
        }
      }
      // Menu background
      @if $no-banner-nav-background-colour != $nav-background-colour {
        .menuMain {
          background-color: $no-banner-nav-background-colour;
          @if $no-banner-nav-background-colour != transparent {
            max-width: $nav-normal-max-width;
            @include parentless-container-wrapper($nav-normal-max-width);
          }
        }
      }
    }
  }
}
