// .mainLogo {
//   cursor: auto!important;
// }
.page-no-banner {
  @media (min-width: map-get($breakpoints, lg)) {
    .pageHeader {
      margin-bottom: 210px;
    }
    .menuMain .topLevel {
      margin-bottom: 20px;
    }
  }
}
h2.carouselSlideHeading {
  //font-family: 'Dancing Script', cursive;
  margin-bottom: .25em;
  a:hover {
    text-decoration: none;
    color: rgba(255,255,255, .8);
  }
}
.carouselControls a{
  padding: 20px;
  width: 80px;
  height: 80px;
  color: transparent;
  &:before {
    color: white;
  }
}
a.carouselControlPrev {
  &:before {
    content: "\f177";
  }
}
a.carouselControlNext {
  &:before {
    content: "\f178";
  }
}
ul.footerLinks {
  margin: 0 0 20px;
  li {
    display: inline-block;
    margin: 0 10px;
  }
}
.appealsList {
  a.cta-button.donate {
    display: none;
  }
  .tabsWrapper {
    display: none;
  }
}